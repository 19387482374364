<template>
  <div class="myGoodsView">
    <van-sticky>
      <van-nav-bar title="我的商品" left-arrow @click-left="onClickLeft" />
    </van-sticky>

    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" loading-text="..." @load="onLoad">
      <van-cell v-for="(item, key) in list" :key="key" class="xiax_item">
        <div class="left">
          <van-image width="3rem" height="3rem" fit="contain" :src="$imageUrl + item.image" />
        </div>
        <div class="right">
          <span class="desc">
            <em>{{ item.title }} ({{ item.status_name }})</em>
          </span>

          <span class="money">
            订单金额：
            <em>¥{{ item.money }}</em>
          </span>
          <span class="time">买入时间：{{ item.addtime }}</span>
          <span class="time" v-if="item.last_release_sec > 0">释放时间：{{ item.last_release_sec }}</span>
          <span class="time" v-if="item.status == -1">
            订单操作：
            <van-button type="danger" size="mini" @click="onDetail(item)">提货</van-button>
          </span>
        </div>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { formatDate } from '@/utils/func';
import { getMyGoodLists } from '@/api/goods';
export default {
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 15,
      loading: false,
      finished: false,
    };
  },
  watch: {},
  methods: {
    onDetail(item) {
      this.$router.push({
        path: '/main/user/mygoods/pickup_goods',
        query: { id: item.id, title: item.title },
      });
    },
    onClickLeft() {
      this.$router.push('/main/user');
    },
    //日志列表
    get_my_good_lists() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      getMyGoodLists({
        page: this.page,
        pageSize: this.pageSize,
      })
        .then((response) => {
          this.list.push.apply(this.list, response.result);
          // 加载状态结束
          this.loading = false;
          this.page++;
          if (response.result.length == 0) {
            this.finished = true;
          }
        })
        .catch((error) => {});
    },
    onLoad() {
      this.get_my_good_lists();
    },
  },
};
</script>
<style lang="stylus">
.myGoodsView
  .van-list
    margin-top: 0.2rem
    margin: 0.2rem 0.2rem 0
    .xiax_item
      padding: 0.25rem
      span
        display: block
      .van-cell__value
        display: flex
        .left
          margin-right: 0.3rem
          display: flex
          align-items: center
        .right
          color: #777
          font-size: 0.36rem
          .desc
            font-weight: 500
          .money
            em
              color: #f70101
          .time
            em
              color: #c30303
            .van-count-down
              color: #a54d0d
              display: inline-block
            .van-button
              line-height: 0.57rem
</style>
